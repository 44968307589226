<template>
  <div>
    <h2 class="th-title">Penarikan</h2>
    <a-drawer
      width="700"
      placement="right"
      :visible="visibleWithdrawalDetailModal"
      title="Detail Penarikan"
      @close="hideWithdrawalDetailModal"
    >
      <img v-if="dataDetail.status == 'pending'" style="margin-left: 40%; margin-bottom: 5%;" src="@/assets/img/pending.png" />
      <img v-else-if="dataDetail.status == 'success'" style="margin-left: 40%; margin-bottom: 5%;" src="@/assets/img/success.png" />
      <img v-else style="margin-left: 40%; margin-bottom: 5%;" src="@/assets/img/failed.png" />
      <a-descriptions bordered :column="2">
        <a-descriptions-item :span="2" label="Status">
          <a-badge :status="(dataDetail.status == 'success') ? 'success' : (dataDetail.status == 'pending') ? 'processing' : 'error'" :text="capitalizeFirstLetter(dataDetail.status)" />
        </a-descriptions-item>
        <a-descriptions-item :span="2" label="Id">{{dataDetail._id}}</a-descriptions-item>
        <a-descriptions-item :span="2" label="Xendit Id">{{dataDetail.withdrawalDetail?.id}}</a-descriptions-item>
        <a-descriptions-item label="Total Ruby">{{commaNumber(dataDetail.basePriceInCoin)}}</a-descriptions-item>
        <a-descriptions-item label="Total Rupiah">IDR {{commaNumber(dataDetail.basePriceInCurrency)}}</a-descriptions-item>
        <a-descriptions-item label="List Biaya">
          <a-list :data-source="dataDetail.fees">
            <template #renderItem="{ item }">
              <a-list-item>{{item.type}}: {{commaNumber(item.value)}}</a-list-item>
            </template>
          </a-list>
        </a-descriptions-item>
        <a-descriptions-item label="Total Penarikan">IDR {{commaNumber(dataDetail.totalWithdraw)}}</a-descriptions-item>
        <a-descriptions-item label="Tanggal Permintaan">{{dataDetail.requestDate}}</a-descriptions-item>
        <a-descriptions-item label="Tanggal Persetujuan">{{dataDetail.approvedDate}}</a-descriptions-item>
        <a-descriptions-item label="Detail Akun Bank">
          Bank Name: {{dataDetail.withdrawalDetail?.bankName}}
          <br />
          Account Number: {{dataDetail.withdrawalDetail?.accountNumber}}
          <br />
          Account Holder: {{dataDetail.withdrawalDetail?.holderName}}
        </a-descriptions-item>
      </a-descriptions>
    </a-drawer>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
          <a-input
            style="width: 220px"
            :value="search"
            placeholder="Cari dengan XenditId"
            @change="handleSearch"
          >
            <template #prefix>
              <span>
                <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
              </span>
            </template>
          </a-input>
          <a-select class="ml-2" style="width: 100px;" :value="status" @change="handleSelect" >
            <a-select-option value="all">
              All
            </a-select-option>
            <a-select-option value="pending">
              Dalam Proses
            </a-select-option>
            <a-select-option value="success">
              Sukses
            </a-select-option>
            <a-select-option value="cancel">
              Gagal
            </a-select-option>
          </a-select>
      </div>
      <div>
        <a-table :class="$style.table" :columns="columns" :data-source="dataTable" :loading="loadingTable" bordered :pagination="globalPagination" @change="onTableChange" >
          <template #key="{ text }">
            <a-button type="link" @click="openWithdrawDetail(text)" >{{text}}</a-button>
          </template>
          <template #creatorUsername="{ text, record }">
            <span>
              <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.creatorId } }"
                >{{ text }}</router-link>
            </span>
          </template>
          <template #status="{ text }">
            <a-tag :color="text == 'failed' ? 'red' : text == 'success' ? 'green' : 'blue'" >{{text}}</a-tag>
          </template>
          <template #calculatedRubies="{ text }">
            {{commaNumber(text, '.')}}
          </template>
          <template #totalWithdraw="{ text }">
            IDR {{commaNumber(text, '.')}}
          </template>
          <template #fraudStatus="{ text }">
            <a-tag :color="text == 'secure' ? 'green' : 'red'" >{{text}}</a-tag>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import priceFormat from '@/helpers/rupiahPrice';
import { commaNumber } from '@/helpers/numberHelper';
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined , CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';

const columns = [
  {
    title: 'No',
    dataIndex: 'rowNumber',
    key: 'rowNumber',
    slots: { customRender: 'rowNumber' },
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Id',
    dataIndex: 'key',
    key: 'key',
    slots: { customRender: 'key' },
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Xendit Id',
    dataIndex: 'xenditId',
    key: 'xenditId',
    slots: { customRender: 'xenditId' },
    align: 'center',
    sorter: (a, b) => a.xenditId - b.xenditId,
  },
  {
    title: 'Username',
    dataIndex: 'creatorUsername',
    key: 'creatorUsername',
    slots: { customRender: 'creatorUsername' },
    align: 'center',
  },
  {
    title: 'Status Penipuan',
    dataIndex: 'fraudStatus',
    key: 'fraudStatus',
    slots: { customRender: 'fraudStatus' },
    align: 'center',
  },
  {
    title: 'Total Ruby',
    dataIndex: 'calculatedRubies',
    key: 'calculatedRubies',
    slots: { customRender: 'calculatedRubies' },
    align: 'center',
  },
  {
    title: 'Total Penarikan',
    dataIndex: 'totalWithdraw',
    key: 'totalWithdraw',
    slots: { customRender: 'totalWithdraw' },
    align: 'center',
  },
  {
    title: 'Tanggal Pengajuan',
    dataIndex: 'requestDate',
    key: 'requestDate',
    slots: { customRender: 'requestDate' },
    align: 'center',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.requestDate, 'DD MMMM YYYY, h:mm') - moment(b.requestDate, 'DD MMMM YYYY, h:mm'),
  },
  {
    title: 'Tanggal Persetujuan',
    dataIndex: 'approvedDate',
    key: 'approvedDate',
    slots: { customRender: 'approvedDate' },
    align: 'center',
    sorter: (a, b) => moment(a.approvedDate, 'DD MMMM YYYY, h:mm') - moment(b.approvedDate, 'DD MMMM YYYY, h:mm'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
    fixed: 'right',
  },
];

export default {
  components: {
    SearchOutlined,
    // CheckCircleOutlined,
    // ClockCircleOutlined,
    // CloseCircleOutlined,
    // PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
  },
  setup() {
    const store = useStore()
    let dataTable = ref([])
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let status = ref('all')
    let loadingTable = ref(false)
    let search = ref('')
    let sortBy = ref('createdAt')
    let order = ref('desc')
    let visibleWithdrawalDetailModal = ref(false)
    const dataDetail = ref({})

    onMounted(() => {
      fetchWithdrawal()
    })

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSearch = (e) => {
      // console.log('handleSearch', e.target.value);
      search.value = e.target.value;
      globalPagination.value = { ...globalPagination.value, current: 1 };
      fetchWithdrawal(e.target.value);
    }

    const handleSelect = (val) => {
      // console.log('handleSelect val', val)
      status.value = val;
      globalPagination.value = { ...globalPagination.value, current: 1 };
      fetchWithdrawal()
    }

    const fetchWithdrawal = async (search) => {
      const API_getAllWithdrawal = await store.dispatch('withdrawal/FETCH_ALL_WITHDRAWAL', { search, status: status.value, page: globalPagination.value.current, limit: globalPagination.value.pageSize, sortBy: sortBy.value, order: order.value });
      
      if (API_getAllWithdrawal) {
        const { data, totalAllData } = API_getAllWithdrawal;
        // console.log('data', data)
        globalPagination.value = { ...globalPagination.value, total: totalAllData }
        dataTable.value = data
      }
    }

    const onTableChange = async(pagination, filters, sorter) => {
      // console.log('pagination, filters, sorter', pagination, filters, sorter)
      globalPagination.value.current = pagination.current;
      if (sorter) {
        if (sorter.columnKey == 'key') sortBy.value = '_id';
        else if (sorter.columnKey == 'requestDate') sortBy.value = 'createdAt';
        else if (sorter.columnKey == 'xenditId') sortBy.value = 'withdrawalDetail.id';
        else if (sorter.columnKey == 'approvedDate') sortBy.value = 'approvedAt';
        else if (sorter.columnKey == 'totalWithdraw') sortBy.value = 'totalPrice';
        else sortBy.value = sorter.columnKey;
        order.value = sorter.order == 'ascend' ? 'asc' : 'desc';
      }
      fetchWithdrawal()
    }

    const openWithdrawDetail = async(val) => {
      const API_getWithdrawalDetail = await store.dispatch('withdrawal/FETCH_WITHDRAWAL_DETAIL', { id: val });
      // console.log('API_getWithdrawalDetail', API_getWithdrawalDetail)
      if (API_getWithdrawalDetail) dataDetail.value = API_getWithdrawalDetail;
      visibleWithdrawalDetailModal.value = true;
    }

    const hideWithdrawalDetailModal = () => {
      visibleWithdrawalDetailModal.value = false
    }

    return {
      search,
      columns,
      dataTable,
      loadingTable,
      priceFormat,
      status,
      handleSearch,
      onTableChange,
      globalPagination,
      commaNumber,
      sortBy,
      order,
      handleSelect,
      openWithdrawDetail,
      visibleWithdrawalDetailModal,
      hideWithdrawalDetailModal,
      dataDetail,
      capitalizeFirstLetter,
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>