export const simplifyNumber = (digit = 0) => {
  if (digit > (-1 * Math.pow(10, 3)) && digit < Math.pow(10, 3)) return digit
  if (digit > (-1 * Math.pow(10, 6)) && digit < Math.pow(10, 6)) {
    const thousand = (digit / Math.pow(10, 3)).toFixed(1)
    // const hundred = (digit / 100).toFixed(2)
    return thousand + 'K'
  }
  const million = (digit / Math.pow(10, 6)).toFixed(1)
  // const hundred = (digit / 100).toFixed(2)
  return million + 'M'
}

export const commaNumber = (digit, unit = ',') => {
  if (isNaN(digit)) return 0
  if (digit > (-1 * Math.pow(10, 3)) && digit < Math.pow(10, 3)) return digit
  const isNegative = digit < 0
  const newDigit = String(Math.abs(digit))
  const digitSplitWithDecimal = newDigit.split('.')
  const digitArr = digitSplitWithDecimal[0].split('')
  let digitConvert = []
  let counter = 1
  for (let i = digitArr.length - 1; i >= 0; i--) {
    if (counter !== 4) {
      counter++
      digitConvert.unshift(digitArr[i])
      continue
    }
    counter = 2
    digitConvert.unshift(unit)
    digitConvert.unshift(digitArr[i])
  }

  return digitConvert.join('') + (digitSplitWithDecimal[1] ? `${unit === '.' ? ',' : '.'}${digitSplitWithDecimal[1]}` : '')
}